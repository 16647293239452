const routes = [
    {
        path: "/excel/import/templates",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "excelimporttemplate_index"
        }
    }
];

export default routes;
