const routes = [
    {
        path: "/student/club/member/search",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_index"
        }
    }
];

export default routes;
