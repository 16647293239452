const routes = [
    {
        path: "/student/clubs",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            //permission: 'studentclub_index'
            permission: 'studentcourse_index'
        }
    }
];

export default routes;
