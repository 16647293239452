import Vue from "vue";
import App from "./App.vue";

// EventBus
export const EventBus = new Vue();

// Plugins
import Store from "./plugins/Store";
import Router from "./plugins/Router";
import i18n from "./plugins/i18n";
import Ckeditor from "./plugins/Ckeditor";
import "./plugins/Filters";
import "./plugins/Directives";
import "./plugins/Vee-Validate";
import "./plugins/Vue-Phone-Number-Input";
import GlobalRegistration from "@/plugins/GlobalRegistration";

// Import
import VueMeta from "vue-meta";
import VueAutosuggest from "vue-autosuggest";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSidebarMenu from "vue-sidebar-menu";
import VueTheMask from "vue-the-mask";
import VCalendar from "v-calendar";
import Multiselect from "/src/libs/vue-multiselect.min";
import Toast from "vue-toastification";
import VueSweetalert2 from "vue-sweetalert2";

import "@/assets/css/goodtable/goodtable.css";
import VueNestable from "vue-nestable";

// Style
import "./assets/css/components/sweetalert.css";
import "remixicon/fonts/remixicon.css";
import "nprogress/nprogress.css";
import "./assets/css/style.scss";
import "./assets/css/extra.css";

import moment from "moment";
Vue.prototype.$moment = moment;

import _ from "lodash";
Vue.prototype.$_ = _;

// ???
Vue.config.productionTip = false;

// Vue Good Table
import VueGoodTablePlugin from "vue-good-table";
Vue.use(VueGoodTablePlugin);

import cloneButton from "@/components/elements/CloneButton.vue";

Vue.component("b-button", cloneButton);

// Using
Vue.use(VueTheMask);
Vue.use(Router);
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAutosuggest);
Vue.use(VCalendar, { componentPrefix: "v" });
Vue.use(VueMeta);
Vue.use(Toast, { maxToasts: 2000 });

// Sweet Alert
const options = {
    confirmButtonColor: "#41b882",
    cancelButtonColor: "#ffffff",
    icon: "question",
};
Vue.use(VueSweetalert2, options);

// Nestable
Vue.use(VueNestable);

GlobalRegistration({ Vue });

// Html To Paper
import VueHtmlToPaper from "vue-html-to-paper";
const vueHtmlToPaperOptions = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    timeout: 1000,
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "http://localhost:3000/print.scss",
    ],
    autoClose: true,
    windowTitle: window.document.title,
};
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);

// Helpers
import downloadFile, {
    downloadExcelFile,
    pdfPrint,
} from "@/helpers/downloadFile";
import getButtonColors from "@/helpers/getButtonColors";
import getDayText from "@/helpers/getDayText";
import getYesNoText from "@/helpers/getYesNoText";
import nl2br from "@/helpers/nl2br";
import openInNewWindow from "@/helpers/openInNewWindow";
import showErrors, { getErrorMessage } from "@/helpers/showErrors";
import { trim } from "core-js/internals/string-trim";

export function checkPermission(permission) {
    const user = Store.getters["auth/getUser"];
    if (!Array.isArray(user.permissions)) {
        return false;
    }
    return user.permissions.includes(permission);
}

Vue.mixin({
    methods: {
        getButtonColors,
        getDayText,
        getYesNoText,
        nl2br,
        openInNewWindow,
        getErrorMessage,
        showErrors,
        _pdfPrint: pdfPrint,
        checkPermission,
        getLocaleText: function (row, col, $return = "") {
            if (!row) return "";
            if (
                this.$i18n.locale === "tr" &&
                typeof row[col] != "undefined" &&
                row[col]
            ) {
                return row[col];
            } else if (
                typeof row[col + "_en"] != "undefined" &&
                row[col + "_en"]
            ) {
                return row[col + "_en"];
            }
            return $return;
        },
        getLocaleField: function (col) {
            if (this.$i18n.locale === "en") {
                return col + "_en";
            }
            return col;
        },
        getStatusText: function (status) {
            if (this.$i18n.locale === "en") {
                if (status === "a") {
                    return "Active";
                } else if (status === "p") {
                    return "Passive";
                }
            }

            if (status === "a") {
                return "Aktif";
            } else if (status === "p") {
                return "Pasif";
            }
        },
        getSemester: function (number) {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return this.$t("autumn");
                case 2:
                    return this.$t("spring");
                case 3:
                    return this.$t("summer");
                case 4:
                    return this.$t("yearly");
                default:
                    return number;
            }
        },
        getSectionType: function (number) {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return this.$t("theoric");
                case 2:
                    return this.$t("practical");
                default:
                    return number;
            }
        },
        getPlacementType: function (type) {
            switch (type) {
                case "placement":
                    return this.$t("placement_exam");
                case "proficiency":
                    return this.$t("proficiency_exam");
                default:
                    return "";
            }
        },
        getPlacementExamType: function (type) {
            switch (type) {
                case "in_class":
                    return this.$t("in_class");
                case "online":
                    return this.$t("online");
                case "hybrid":
                    return this.$t("hybrid");
                default:
                    return "";
            }
        },
        getYesNoSelectboxOptions() {
            return [
                { text: this.$t("select"), value: null },
                { text: this.$t("yes"), value: 1 },
                { text: this.$t("no"), value: 0 },
            ];
        },
        translate(text) {
            return this.$t(text);
        },
        toUpperCase(text) {
            return this.$t(text).toLocaleUpperCase(i18n.locale);
        },
        confirmModal(msg, callback) {
            this.$store.commit("setConfirmModal", { msg, callback });
        },
        deleteModal(callback) {
            this.$store.commit("setDeleteModal", { callback });
        },
        groupBy(arr, criteria) {
            const newObj = arr.reduce(function (acc, currentValue) {
                if (!acc[currentValue[criteria]]) {
                    acc[currentValue[criteria]] = [];
                }
                acc[currentValue[criteria]].push(currentValue);
                return acc;
            }, {});
            return newObj;
        },
        getOptionText(options, value) {
            if (!value) {
                return "";
            }
            var found = "";
            options.forEach((item) => {
                if (item.value === value) {
                    found = item.text;
                }
            });
            return found;
        },
        getOptionItem(options, value) {
            var found = null;
            options.forEach((item) => {
                if (item.value === value) {
                    found = item;
                }
            });
            return found;
        },
        getParameterText(options, value) {
            var found = "";
            options.forEach((item) => {
                if (item.code === value) {
                    found = this.getLocaleText(item, "name");
                }
            });
            return found;
        },
        br2space(str) {
            return str.replace(/<br\s*\/?>/gm, " ");
        },
        isNull(value) {
            return typeof value != "undefined" || value !== null || value != ""
                ? true
                : false;
        },
        isNotNull(value) {
            return typeof value != "undefined" && value !== null && value != ""
                ? true
                : false;
        },
        isNotNullValue(value, nullValue = "-") {
            return typeof value != "undefined" && value !== null && value != ""
                ? value
                : nullValue;
        },
        getObjectValue(object, value, nullValue = "-") {
            if (
                object &&
                typeof object[value] != "undefined" &&
                object[value] != null &&
                trim(object[value]) != ""
            ) {
                return object[value];
            }
            return nullValue;
        },
        getDate(format = "YYYY-MM-DD") {
            moment.locale("tr");
            return moment().format(format);
        },
        toLocaleDate(date, format = "") {
            if (!date) {
                return "";
            }
            if (this.$i18n.locale === "tr") {
                moment.locale("tr");
                if (format) {
                    return moment(date, "YYYY-MM-DD").format(format);
                }
                return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
            } else {
                moment.locale("en");
                if (format) {
                    return moment(date, "YYYY-MM-DD").format(format);
                }
                return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
            }
        },
        toLocaleDateTime(date) {
            return moment(date, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm");
        },
        toLocaleTimeDate(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm");
        },
        formatNumber(value) {
            return this.$options.filters.formatNumber(value);
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },
        spaceToBR(text) {
            return text.replaceAll(" ", "<br>");
        },
        toast(data) {
            if (data.success) {
                this.$toast.success(this.$t("api." + data.message));
            } else {
                this.$toast.error(this.$t("api." + data.message));
            }
        },
        _downloadFile: downloadFile,
        _downloadExcelFile: downloadExcelFile,
        _openFile(response) {
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        _openBinaryPdfInNewTab(response) {
            let binaryData = [];
            binaryData.push(response.data);
            let fileURL = window.URL.createObjectURL(
                new Blob(binaryData, { type: "application/pdf" })
            );

            window.open(fileURL);
        },
        setBooleans(form) {
            Object.keys(form).forEach((key) => {
                let val = form[key];
                if (val === true) {
                    val = 1;
                } else if (val === false) {
                    val = 0;
                }
                form[key] = val;
            });
        },
        objectToArrayObject(data) {
            let response = [];
            if (Object.keys(data)) {
                Object.keys(data).forEach((key) => {
                    response.push({
                        key: key,
                        ...data[key],
                    });
                });
            }
            return response;
        },

        getInfoMessage(data) {
            try {
                let msg = this.$t("api." + data.info);
                if (
                    typeof data.info_params != "undefined" &&
                    data.info_params
                ) {
                    Object.keys(data.info_params).forEach((key) => {
                        msg = msg.replaceAll("#" + key, data.info_params[key]);
                    });
                }

                return msg;
            } catch (e) { }
        },
        getWarningMessage(data) {
            try {
                let msg = this.$t("api." + data.warning);
                if (
                    typeof data.warning_params != "undefined" &&
                    data.warning_params
                ) {
                    Object.keys(data.warning_params).forEach((key) => {
                        msg = msg.replaceAll("#" + key, data.warning_params[key]);
                    });
                }

                return msg;
            } catch (e) { }
        },
        showMessage(response) {
            this.$toast.success(this.$t("api." + response.data.message));
            if (response.data.data && response.data.data.info) {
                let msg = this.getInfoMessage(response.data.data);
                if (typeof msg != "undefined") {
                    this.$toast.success(msg);
                }
            }
        },
        getDaysObject() {
            return [
                { value: null, text: i18n.t("select") },
                { value: 1, text: i18n.t("monday") },
                { value: 2, text: i18n.t("tuesday") },
                { value: 3, text: i18n.t("wednesday") },
                { value: 4, text: i18n.t("thursday") },
                { value: 5, text: i18n.t("friday") },
                { value: 6, text: i18n.t("saturday") },
                { value: 7, text: i18n.t("sunday") },
            ];
        },
        setWithExpiry(key, value, ttl = 3600000, originalKey = false) {
            const now = new Date();

            if (ttl == 0) {
                ttl = 3600000;
            }

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            };
            if (!originalKey) {
                key = this.getCacheKey(key);
            }
            try {
                localStorage.removeItem(key);
                localStorage.setItem(key, JSON.stringify(item));
            } catch (e) { }
        },
        getCacheKey(key) {
            let user = JSON.parse(localStorage.getItem("user"));
            if (user && user.active_role) {
                let pathname = window.location.pathname
                    .split("/")
                    .map(function (item) {
                        if (!isNaN(parseInt(item))) {
                            return "*";
                        }
                        return item;
                    })
                    .join("/");
                key = user.active_role.name + pathname + "_" + key;
            }
            return key;
        },
        getWithExpiry(key, originalKey = false) {
            if (!originalKey) {
                key = this.getCacheKey(key);
            }
            const itemStr = localStorage.getItem(key);
            // if the item doesn't exist, return null
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date();
            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem(key);
                return null;
            }
            return item.value;
        },
        getStorageKeyToValue(key, field = "code", value, originalKey = false) {
            const data = this.getWithExpiry(key, originalKey);
            const findItem = data.items.find((i) => i[field] === value);
            if (findItem) {
                return this.getLocaleText(findItem, "name");
            }

            return value;
        },
        clearExpiredCache() {
            let keys = Object.keys(localStorage),
                i = 0,
                key;

            for (; (key = keys[i]); i++) {
                try {
                    const item = JSON.parse(localStorage.getItem(key));
                    if (item.expiry) {
                        var date = new Date();
                        if (date.getTime() > item.expiry) {
                            localStorage.removeItem(key);
                        }
                    }
                } catch (e) { }
            }
        },
        currentDate() {
            let d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        base64Encode(data) {
            return new Promise((resolve, reject) => {
                if (!data) return resolve()
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        convertToBuffer(data) {
            //bu function base64 ile gelen veriyi arraybuffera çevirir
            const buffer = new ArrayBuffer(data.length);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < data.length; i++) {
                view[i] = data.charCodeAt(i) & 0xff;
            }
            return buffer;
        },
    },
});

String.prototype.toUpper = function () {
    return this.toLocaleUpperCase(i18n.locale);
};

Vue.component("multiselect", Multiselect);
new Vue({
    render: (h) => h(App),
    i18n,
    router: Router,
    store: Store,
}).$mount("#app");
