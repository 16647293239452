const routes = [
    {
        path: "/student/club/selection/search",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_index"
        }
    },
    {
        path: '/student/clubs/:id/members',
        component: () => import('../pages/members/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'studentcourse_index'
        }
    },
    {
        path: '/student/clubs/:id/sms-list',
        component: () => import('../pages/smsList/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'studentcourse_index'
        }
    },
];

export default routes;
