const routes = [
    {
        path: "/registration/dates",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "registrationdate_index"
        }
    }
];

export default routes;